import React from "react";

function Login() {
  return (
    <>
      <div
        className="relative h-screen w-full flex justify-center items-center pt-20 md:pt-0 "
        style={{
          background: `url(${process.env.PUBLIC_URL + "/assets/earth.jpg"})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          opacity: "70",
        }}
      >
        <div>
          <h1 className="absolute text-white left-5 top-5">
            ECLIPSE by Webgroo
          </h1>
        </div>
        <div className="absolute h-[450px] w-96 bg-zinc-300 rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10  ">
          <div className="p-8 my-5">
            {" "}
            <h1 className="text-white text-4xl pb-2">Login</h1>
            <p className="text-white text-sm pb-5">
              Keep it all together and you'll be fine{" "}
            </p>
            <input
              type="text"
              placeholder="Email"
              className="bg-transparent border border-zinc-500 w-full mb-4 py-2 pl-2"
            ></input>
            <input
              type="text"
              placeholder="Password"
              className="bg-transparent border border-zinc-500 w-full mb-4 py-2 pl-2"
            ></input>
            <p className="text-white text-sm mb-16">Forgot Password</p>
            <button className="w-full px-2 py-2 bg-violet-600 text-white hover:bg-gradient-to-r from-violet-600 to-violet-800">
              Login
            </button>
          </div>
        </div>
      </div>{" "}
    </>
  );
}

export default Login;
